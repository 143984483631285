// Powered By
// import iforte from './iforte.png'

// Sponsors
import pusri from './sponsor/pusri.png'
import sotoabah from './sponsor/sotoabah.png'
import pelindo from './sponsor/pelindo.png'
import logocv from './sponsor/logocv.jpg'
import iwakTunu from './sponsor/iwakTunu.jpg'
import pempekMavie from './sponsor/pempekMavie.jpg'
import tel from './sponsor/tel.jpg'
import barokahKitchen from './sponsor/barokahKitchen.jpg'

// Media Partners
import himsi from './medpart/himsi.png'
import fosmab from './medpart/fosmab.png'
import logotekkom from './medpart/logotekkom.png'
import gdscPolsri from './medpart/gdscPolsri.png'
import globalRadio from './medpart/globalRadio.png'
import hmjElektro from './medpart/hmjElektro.png'
import hmjTekpil from './medpart/hmjTekpil.jpg'
import ikutEvent from './medpart/ikutEvent.png'
import mars from './medpart/mars.png'
import wps from './medpart/wps.png'
import eventDetect from './medpart/eventDetect.png'
import bem from './medpart/bem.PNG'
import hmme from './medpart/hmme.jpg'
import laNugraha from './medpart/laNugraha.PNG'
import eds from './medpart/eds.PNG'
import inf from './medpart/inf.jpg'
import lombaNasional from './medpart/lombaNasional.jpg'
import hmjAB from './medpart/hmjAB.png'
import terciduk from './medpart/terciduk.png'
import hmjAkuntansi from './medpart/hmjAkuntansi.png'


const data = [
    // Powered By
    // {
    //     name: 'poweredby',
    //     image: iforte,
    // },

    //  Sponsors
    {
        name: 'sponsor',
        image: pusri,
    },
    {
        name: 'sponsor',
        image: sotoabah,
    },
    {
        name: 'sponsor',
        image: pelindo,
    },
    {
        name: 'sponsor',
        image: logocv,
    },
    {
        name: 'sponsor',
        image: iwakTunu,
    },
    {
        name: 'sponsor',
        image: pempekMavie,
    },
    {
        name: 'sponsor',
        image: tel,
    },
    {
        name: 'sponsor',
        image: barokahKitchen,
    },



    // Media Partners
    {
        name: 'medpart',
        image: himsi,
    },
    {
        name: 'medpart',
        image: fosmab,
    },
    {
        name: 'medpart',
        image: logotekkom,
    },
    {
        name: 'medpart',
        image: gdscPolsri,
    },
    {
        name: 'medpart',
        image: globalRadio,
    },
    {
        name: 'medpart',
        image: hmjElektro,
    },
    {
        name: 'medpart',
        image: hmjTekpil,
    },
    {
        name: 'medpart',
        image: ikutEvent,
    },
    {
        name: 'medpart',
        image: mars,
    },
    {
        name: 'medpart',
        image: wps,
    },
    {
        name: 'medpart',
        image: eventDetect,
    },
    {
        name: 'medpart',
        image: bem,
    },
    {
        name: 'medpart',
        image: hmme,
    },
    {
        name: 'medpart',
        image: laNugraha,
    },
    {
        name: 'medpart',
        image: eds,
    },
    {
        name: 'medpart',
        image: inf,
    },
    {
        name: 'medpart',
        image: lombaNasional,
    },
    {
        name: 'medpart',
        image: hmjAB,
    },
    {
        name: 'medpart',
        image: terciduk,
    },
    {
        name: 'medpart',
        image: hmjAkuntansi,
    },

    
    
    
    
    
    

]

export default data